<template>
  <el-dialog v-if="dataList" :title="title" @closed="_closed" :visible.sync="visible">
    <el-card>
      <el-radio-group v-model="selType" @change="typeChange">
        <el-radio-button label="">全部</el-radio-button>
        <el-radio-button label="undo">未上报</el-radio-button>
        <el-radio-button label="unRisk">上报正常</el-radio-button>
        <el-radio-button label="risk">上报异常</el-radio-button>
        <el-radio-button label="riskDone">异常已处理</el-radio-button>
      </el-radio-group>
    </el-card>
    <el-divider> </el-divider>
    <el-card v-if="reportList">
      <l-table :option="tableOption" :data="reportList">
        <template slot="reportDate" slot-scope="scope">
          <div v-if="scope.row.unReport" class="text-red">{{ scope.row.reportDate }}</div>
          <el-button v-else type="text" @click="$site.view_virusdailyreport_detail(scope.row.reportId)">{{ scope.row.reportDate }}</el-button>
        </template>
        <template slot="frksFlag" slot-scope="scope">
          <span v-if="scope.row.unReport" class="text-red">未上报</span>
          <span v-else-if="scope.row.frksFlag == 0" class="text-gray">无</span>
          <span v-else-if="scope.row.frksFlag == 1" class="text-red text-bold">有</span>
        </template>
        <template slot="workFlag" slot-scope="scope">
          <span v-if="scope.row.unReport" class="text-red">未上报</span>
          <span v-else-if="scope.row.workFlag == 0" class="text-gray">未上班</span>
          <span v-else-if="scope.row.workFlag == 1" class="text-gray">上班</span>
        </template>
        <template slot="temp" slot-scope="scope">
          <span v-if="scope.row.unReport" class="text-red">未上报</span>
          <span v-else-if="!scope.row.temp" class="text-red">未上报</span>
          <span v-else-if="scope.row.temp < 37.2">{{ scope.row.temp }}℃</span>
          <span v-else class="text-red text-bold">{{ scope.row.temp }}℃</span>
        </template>
        <template slot="isExist" slot-scope="scope">
          <span v-if="scope.row.unReport" class="text-red">未上报</span>
          <span v-else-if="scope.row.isExist == 0">无</span>
          <span v-else-if="scope.row.isExist == 1" class="text-red text-bold">有</span>
        </template>
        <template slot="isDangerArea" slot-scope="scope">
          <span v-if="scope.row.unReport" class="text-red">未上报</span>
          <span v-else-if="scope.row.isDangerArea == 0">无</span>
          <span v-else-if="scope.row.isDangerArea == 1" class="text-red text-bold">有</span>
        </template>
        <template slot="isHS48" slot-scope="scope">
          <span v-if="scope.row.unReport" class="text-red">未上报</span>
          <span v-else-if="scope.row.isHS48 == 0" class="text-red text-bold">无</span>
          <span v-else-if="scope.row.isHS48 == 1">有</span>
        </template>
        <template slot="skmColor" slot-scope="scope">
          <div class="flex align-center justify-center">
            <span v-if="scope.row.skmColor === 'G'" class="bg-green shadow" style="padding:10px 30px 10px 30px;"></span>
            <span v-else-if="scope.row.skmColor === 'Y'" class="bg-yellow shadow" style="padding:10px 30px 10px 30px;"></span>
            <span v-else-if="scope.row.skmColor === 'R'" class="bg-red shadow" style="padding:10px 30px 10px 30px;"></span>
          </div>
        </template>
        <template slot="xcmColor" slot-scope="scope">
          <div class="flex align-center justify-center">
            <span v-if="scope.row.xcmColor === 'G'" class="bg-green shadow" style="padding:10px 30px 10px 30px;"></span>
            <span v-else-if="scope.row.xcmColor === 'Y'" class="bg-yellow shadow" style="padding:10px 30px 10px 30px;"></span>
            <span v-else-if="scope.row.xcmColor === 'R'" class="bg-red shadow" style="padding:10px 30px 10px 30px;"></span>
          </div>
        </template>
      </l-table>
    </el-card>
    <div slot="footer" class="dialog-footer">
      <el-button @click="_cancle">取 消</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      title: '', // 内置参数,误删
      visible: true, // 内置参数,误删
      dataList: null,
      reportList: null,
      selType: '',
      tableOption: {
        columns: [
          { label: '序号', type: 'index' },
          { label: '日期', slot: 'reportDate' },
          { label: '姓名', prop: 'userName' },
          { label: '部门', prop: 'departName' },
          { label: '是否上班', slot: 'workFlag' },
          { label: '发热咳嗽', slot: 'frksFlag' },
          { label: '出入宁', slot: 'isExist' },
          { label: '中高风险区', slot: 'isDangerArea' },
          { label: '体温', slot: 'temp' },
          { label: '苏康码', slot: 'skmColor' },
          { label: '行程码', slot: 'xcmColor' },
          { label: '48小时核酸', slot: 'isHS48' }
        ]
      }
    }
  },
  mounted() {
    this.typeChange()
  },
  methods: {
    _closed() {
      // 底层内置方法 误删
      this.onclosed()
    },
    _cancle() {
      // 底层内置方法 误删
      this.visible = false
      this.cancle && this.cancle()
    },
    typeChange() {
      this.reportList = this.dataList.filter(a => {
        if (!this.selType) return true
        else if (this.selType === 'undo') return a.unReport
        else if (this.selType === 'unRisk') return !a.unReport && a.reportState === 0
        else if (this.selType === 'risk') return !a.unReport && a.reportState === 1
        else if (this.selType === 'riskDone') return !a.unReport && a.reportState === 2
      })
    }
  }
}
</script>

<style></style>
