<template>
  <section class="l-content">
    <el-card>
      <el-form :inline="true" :model="condition">
        <el-form-item label="所属部门">
          <l-select v-model="condition.departId" :remote="$api.departList" @change="getData()"></l-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="getStatisticData()">查询</el-button>
        </el-form-item>
      </el-form>
    </el-card>
    <el-divider> </el-divider>
    <el-card v-if="pageData">
      <el-calendar v-model="calendarDay">
        <template slot="dateCell" slot-scope="{ date, data }">
          <el-popover v-if="pageData[data.day]" placement="top-start" :title="data.day" width="200" trigger="hover">
            <div class="calendar_cell" slot="reference" @click="viewList(pageData[data.day])">
              <p class="day">{{ data.day.split('-')[2] }}</p>
              <div class="group">
                <el-button-group>
                  <el-button type="info" v-if="pageData[data.day].undo > 0">{{ pageData[data.day].undo }}</el-button>
                  <el-button type="success" v-if="pageData[data.day].unRisk > 0">{{ pageData[data.day].unRisk }}</el-button>
                  <el-button type="danger" v-if="pageData[data.day].risk > 0">{{ pageData[data.day].risk }}</el-button>
                  <el-button type="warning" v-if="pageData[data.day].riskDone > 0">{{ pageData[data.day].riskDone }}</el-button>
                </el-button-group>
              </div>
            </div>
            <div>
              <p class="padding-mini">未上报数：{{ pageData[data.day].undo }}</p>
              <p class="padding-mini">正常上报数：{{ pageData[data.day].unRisk }}</p>
              <p class="padding-mini">异常上报数：{{ pageData[data.day].risk }}</p>
              <p class="padding-mini">异常已处理数：{{ pageData[data.day].riskDone }}</p>
            </div>
          </el-popover>
          <div v-else class="calendar_cell" slot="reference">
            <p class="day">{{ data.day.split('-')[2] }}</p>
            <div class="group">---</div>
          </div>
        </template>
      </el-calendar>
    </el-card>
  </section>
</template>

<script>
import { mapState } from 'vuex'
import calenderDetail from './_calender_detail'

export default {
  computed: {
    ...mapState(['userInfo'])
  },
  name: 'DailyReportStatistic',
  data() {
    return {
      calendarDay: new Date(),
      condition: {
        keyword: '',
        // enterpriseCode: this.userInfo.enterpriseCode,
        year: null,
        month: null
      },
      pageData: null
    }
  },
  created() {
    this.getData()
  },
  methods: {
    async getData() {
      this.condition.year = this.calendarDay.getFullYear()
      this.condition.month = this.calendarDay.getMonth() + 1
      this.pageData = await this.$lgh.get('api/enterprise/virusdailyreport/calendar', ['enterpriseCode', this.condition])
    },
    detail(item) {
      this.$site.view_virusdailyreport_detail(item.reportId)
    },
    async viewList(data) {
      await this.$lgh.openDialog({
        ins: calenderDetail,
        title: data.day,
        data: {
          dataList: data.list
        }
      })
    }
  },
  watch: {
    calendarDay(val) {
      var y = val.getFullYear()
      var m = val.getMonth() + 1
      if (this.condition.year === y && this.condition.month === m) return
      this.getData()
    }
  }
}
</script>
<style scoped lang="less">
.calendar_cell {
  height: 100%;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  .day {
    position: absolute;
    left: 5px;
    top: 5px;
  }
}
</style>
